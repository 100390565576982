<template>

    <!-- <keep-alive> -->
    <div id="enterSearch" class="list_content">
        <!-- 查询条件 -->
        <el-form :inline="true" :model="formInline" class="demo-form-inline">

            <el-form-item label="">
                <el-input style="width: 100px;" v-model="formInline.name" placeholder="姓名"></el-input>
            </el-form-item>

            <el-form-item label="">
                <el-input style="width: 100px;" v-model="formInline.telphone" placeholder="手机号"></el-input>
            </el-form-item>

            <el-form-item label="">
                <el-input style="width: 100px;" v-model="formInline.province_name" placeholder="省份"></el-input>
            </el-form-item>

            <el-form-item label="">
                <el-input style="width: 100px;" v-model="formInline.city_name" placeholder="城市"></el-input>
            </el-form-item>

            <el-form-item label="">
                <el-input style="width: 100px;" v-model="formInline.ks_type" placeholder="科室"></el-input>
            </el-form-item>

            <el-form-item label="">
                <el-select v-model="formInline.form_status" placeholder="标记状态" style="width: 120px;">
                    <el-option label="全部" value=0></el-option>
                    <el-option label="未操作" value=1></el-option>
                    <el-option label="未接听" value=2></el-option>
                    <el-option label="已致电" value=3></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="">
                <div class="block">
                    <el-date-picker v-model="formInline.create_time" type="datetimerange" align="right"
                        start-placeholder="创建开始日期" end-placeholder="创建结束日期" :default-time="['00:00:01', '23:59:59']"
                        value-format="yyyy-MM-dd HH:mm:ss" style="width: 360px;">
                    </el-date-picker>
                </div>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="cx_sunbmit">查询</el-button>
            </el-form-item>
        </el-form>
        <!-- 列表内容 -->
        <el-table :data="tableData" :cell-style="{ padding: '2px 0' }" height="634" border stripe>
            <el-table-column prop="name" label="姓名" align="center" width="100"></el-table-column>
            <el-table-column prop="telphone" label="手机号" align="center" width="150"></el-table-column>
            <!-- <el-table-column prop="age" label="年龄" width="100" align="center"></el-table-column>
                                <el-table-column prop="gender" label="性别" width="80" align="center"></el-table-column> -->
            <!-- <el-table-column prop="weixin" label="微信" width="120" align="center"></el-table-column>
                                <el-table-column prop="qq" label="QQ" width="80" align="center"></el-table-column> -->
            <el-table-column prop="province_name" width="80" show-overflow-tooltip label="省份"></el-table-column>
            <el-table-column prop="city_name" width="80" show-overflow-tooltip label="城市"></el-table-column>
            <el-table-column prop="ks_type" width="80" show-overflow-tooltip label="科室"></el-table-column>
            <el-table-column v-if="fpztShow" prop="allot_type" width="80" show-overflow-tooltip label="分配状态">
                <template slot-scope="scope">
                    <span v-if="scope.row.allot_type == 1" style="color:red">未分配</span>
                    <span v-else-if="scope.row.allot_type == 2" style="color:green">已分配</span>
                </template>
            </el-table-column>
            <el-table-column prop="form_status" width="80" show-overflow-tooltip label="标记状态"></el-table-column>
            <el-table-column prop="create_time" width="160" show-overflow-tooltip label="创建时间"></el-table-column>
            <el-table-column v-if="fpshShow" prop="allot_date" width="160" show-overflow-tooltip
                label="分配时间"></el-table-column>
            <!-- <el-table-column prop="update_time" width="120" show-overflow-tooltip label="标记时间"></el-table-column> -->
            <el-table-column prop="remark" width="120" show-overflow-tooltip label="备注"></el-table-column>
            <el-table-column label="操作" width="140" align="center">
                <template slot-scope="scope">
                    <el-button v-if="sdfpShow" @click="toSDFP(scope.row)" type="text" size="small">手动分配</el-button>
                    <el-button @click="toFormInfo(scope.row)" type="text" size="small">跟踪记录</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="current_page" :page-sizes="[15, 20, 25]" :page-size="page_size"
            layout="total, sizes, prev, pager, next, jumper" :total="data_num" :sizes='page_num'>
        </el-pagination>
    </div>

</template>

<script>
import LeftMenu from '@/components/left.vue'
import Top from '@/components/top.vue'
import Footer from '@/components/footer.vue'

export default {
    name: 'FormAllListView',
    components: {
        LeftMenu,
        Top,
        Footer
    },
    data() {
        return {
            cache_user: {},
            sec_user: {},
            formInline: {},
            tableData: [],
            current_page: 1, //初始页
            page_size: 15, // 每页的数据
            data_num: 0, // 总条数
            page_num: 0, // 总页数
            userList: [],
            fpztShow: false,
            fpshShow: false,
            sdfpShow: false,
        }
    },
    methods: {
        cx_sunbmit() {
            this.current_page = 1
            this.onSubmit()
        },
        onSubmit() {
            console.log('开始查询!');
            const that = this
            if (that.formInline.allot_type) {
                that.formInline.allot_type = Number(that.formInline.allot_type)
            }
            if (that.formInline.form_status) {
                that.formInline.form_status = Number(that.formInline.form_status)
            }
            that.formInline.current_page = Number(that.current_page)

            if (that.formInline.create_time && that.formInline.create_time.length >= 2) {
                that.formInline.create_time_start = that.formInline.create_time[0]
                that.formInline.create_time_end = that.formInline.create_time[1]
            }
            this.myAxios.post(that.myBaseurl + '/form/list', that.formInline)
                .then(function (res) {
                    if (res.data.Code === '200') {
                        console.log("submit--res : ", res)
                        that.tableData = res.data.Data.List
                        that.data_num = res.data.Data.TotalCount
                        that.page_num = res.data.Data.TotalPage
                        // 添加权限显示
                        if (that.cache_user.user_level == 1 || that.cache_user.user_level == 2) {
                            that.fpztShow = true
                            that.fpshShow = true
                            that.sdfpShow = true
                        }

                        for (let item of that.tableData) {
                            switch (item.form_status) {
                                case 1:
                                    item.form_status = "未操作"
                                    break;
                                case 2:
                                    item.form_status = "未接听"
                                    break;
                                case 3:
                                    item.form_status = "已致电"
                                    break;
                            }
                        }
                    } else {
                        alert("查询失败 : " + res.data.Msg)
                    }
                })

            sessionStorage.setItem('cache_user_page', JSON.stringify(this.formInline))
        },
        // 初始页current_page、初始每页数据数page_size和数据data
        handleSizeChange: function (size) {
            this.page_size = size;
            console.log(this.page_size) //每页下拉显示数据
            this.onSubmit()
        },
        handleCurrentChange: function (current_page) {
            this.current_page = current_page;
            console.log(this.current_page) //点击第几页
            this.onSubmit()
        },

        toFormInfo: function (u) {
            this.$router.push({
                path: '/form/info',
                query: {
                    form_id: u.id
                }
            })
        },

        toSDFP(u) {
            this.$router.push({
                path: '/form/sdfp',
                query: {
                    form_id: u.id
                }
            })
        }
    },

    created() {
        console.log('开始查询!')
        // 从缓存获取user
        this.cache_user = JSON.parse(sessionStorage.getItem('user'))
        // 从缓存获取 列表user
        this.sec_user = JSON.parse(sessionStorage.getItem('sec_user'))
        this.onSubmit()
    },
    mounted() {
        // 监听回车键查询
        let that = this;
        let enterSearchEle = document.getElementById("enterSearch")

        enterSearchEle.addEventListener("keydown", function (e) {
            console.log(e);
            if (e.key == "Enter") {
                that.$xianliu(that.onSubmit, 500)()
            }
        })
    }
}

</script>

<style lang="less" scoped="scoped">
body {
    margin: 0px;
    padding: 0px;
}

.el-main {
    height: calc(100vh - 120px);
}

.list_content {
    text-align: center;
    width: 100%;
}

.el-table-column {
    line-height: 20px;
}

.el-table__row .hover-row {
    background-color: red !important;
}

a {
    text-decoration: none;
}
</style>
